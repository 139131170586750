.btn {
    display: inline-flex;
    align-items: center;
    padding: 11px 24px;
    font-size: $font-size-base !important; // !important needed to override base css
    border-radius: 24px;
    font-family: $font-family-barlow;
    font-weight: $headings-font-weight;
    box-shadow: 2px 2px 8px rgba($base-2, 0.6);
    text-decoration: none;
    transition: 0.3s;

    &-white {
        @extend .btn;
        color: $blue !important; // !important needed to override base css
        background: $white !important; // !important needed to override base css

        &:hover {
            color: $white !important; // !important needed to override base css
            background: $blue !important; // !important needed to override base css
        }
    }
    &-blue {
        @extend .btn;
        color: $white !important; // !important needed to override base css
        background: $blue !important; // !important needed to override base css

        &:hover {
            color: $blue !important; // !important needed to override base css
            background: $white !important; // !important needed to override base css
        }
    }
    &-primary {
        @extend .btn;
        background: $primary;
        color: $white !important;
        border-color: $primary;
        text-decoration: none !important;

        &:hover {
            color: $primary !important; // !important needed to override base css
            background: $white !important; // !important needed to override base css
        }
    }
    &-outline-primary {
        @extend .btn;
        background: $white;
        color: $primary !important;
        border-color: $primary;
        text-decoration: none !important;
       
        &:hover {
            color: $white !important; // !important needed to override base css
            background: $primary !important; // !important needed to override base css
        }
    }
    &-secondary {
        @extend .btn;
        background: $secondary;
        color: $white !important;
        border-color: $secondary;
        text-decoration: none !important;

        &:hover {
            color: $secondary !important; // !important needed to override base css
            background: $white !important; // !important needed to override base css
        }
    }
    &-outline-secondary {
        @extend .btn;
        background: $white;
        color: $secondary !important;
        border-color: $secondary;
        text-decoration: none !important;
      
        &:hover {
            color: $white !important; // !important needed to override base css
            background: $secondary !important; // !important needed to override base css
        }
    }
}
