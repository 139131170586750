.footer {
    border-top: 5px solid $white;

    @include media-breakpoint-up(sm) {
        border-top: 10px solid $white;
    }

    &-top {
        background: $primary !important; // !important needed to override base css
        padding: 45px 0 !important; // !important needed to override base css

        .container-holder {
            display: flex;
            flex-wrap: wrap;

            .page_block.logo {
                @include media-breakpoint-up(md) {
                    @include make-col(4);
                }
            }
            .footer_text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 30px;
                @include make-col(6);

                @include media-breakpoint-up(md) {
                    @include make-col(3);
                }
                p {
                    font-size: $font-size-base;
                    color: $white;
                    margin-bottom: 5px;

                    a {
                        color: $white;
                    }
                }
            }
        }
        + section {
            background: $primary !important; // !important needed to override base css

            .container-six-columns {
                padding: 25px 30px 50px;

                .container-holder {
                    .column {
                        @include media-breakpoint-down(sm) {
                            @include make-col(6);
                            margin: 0 0 45px 0;
                        }
                        .page_block.footer_list {
                            h5 {
                                font-size: $font-size-base * 1.25;
                                color: $white;
                                margin-bottom: 10px;
                                font-weight: 500;
                            }
                            .list {
                                .list-item {
                                    .link {
                                        font-size: $font-size-base_sm;
                                        color: $white;
                                        opacity: 0.7;

                                        &:hover {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                        .page_block.vocabulary_term_list {
                            display: flex;

                            li {
                                a {
                                    font-size: 0;

                                    &:hover {
                                        i {
                                            opacity: 0.7;
                                        }
                                    }

                                    i {
                                        font-size: $font-size-base * 1.5;
                                        color: $white;
                                    }
                                }
                                &:not(:last-child) {
                                    margin: 0 20px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-socket {
        background: $primary !important; // !important needed to override base css
        padding: 20px 0 !important; // !important needed to override base css

        .container-default {
            .container-holder {
                .footer_link {
                    margin-bottom: 20px;

                    .list {
                        @include media-breakpoint-down(sm) {
                            display: flex !important; // !important needed to override base css
                            flex-wrap: wrap;
                        }
                        &-item {
                            .link {
                                color: $white;
                                font-size: $font-size-base_sm;
                                opacity: 0.7;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                            &:not(:last-child) {
                                &::after {
                                    content: "-";
                                    margin-left: 10px;
                                    color: $white;
                                    opacity: 0.7;
                                }
                            }
                            &:nth-child(2) {
                                @include media-breakpoint-down(sm) {
                                    &::after {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
