.content.home {
    position: relative;
    padding: 70px 0;

    &::after {
        content: "";
        background: url("/images/background-tile.jpg") no-repeat center / 100% 100%;
        opacity: 0.3;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }

    .container-default {
        .container-holder {
            text-align: center;

            h1 {
                text-align: center;
            }
            p {
                max-width: 732px;
                text-align: center;
                margin: auto auto 14px;
            }
            .btn {
                @extend .btn-white;
                margin-bottom: 30px;
            }
            .grid {
                //flex-wrap: nowrap;

                .item {
                    @include make-col(2);
                    border-style: solid;
                    border-color: $white;
                    border-style: solid;
                    border-width: 5px;
                    padding: 0;
                    margin-left: -10px;
                    min-width: 210px;

                    @include media-breakpoint-up(sm) {
                        border-width: 10px;
                    }
                    &:first-of-type {
                        border-left-width: 5px;
                        margin: 0 0 30px 0;

                        @include media-breakpoint-up(sm) {
                            border-left-width: 10px;
                        }
                    }
                    .card {
                        background: transparent;
                        border: none;
                        overflow: unset;

                        &-body {
                            background: transparent;
                            position: relative;
                            padding: 0;

                            .card-caption {
                                position: absolute;
                                bottom: -30px;
                                display: flex;
                                left: 50%;
                                transform: translateX(-50%);
                                justify-content: center;
                                padding: 0px 10px;

                                .card-title-link {
                                    background: $secondary;
                                    padding: 10px 30px;
                                    color: $white;
                                    box-shadow: 3px 3px 6px rgba($base-2, 0.8);

                                    &:hover {
                                        background: $white;
                                        color: $blue;
                                    }

                                    @include media-breakpoint-up(xl) {
                                        padding: 10px 60px;
                                    }

                                    .card-title {
                                        font-family: $font-familty-nothing;
                                        font-size: $font-size-base;
                                        font-weight: $font-weight-regular;
                                    }
                                }
                            }
                            .card-buttons {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.bg-dark {
    background: $secondary !important; // !important needed to override base css
    padding: 35px 0 45px;
    border-top: 5px solid $white;
    border-bottom: 5px solid $white;

    @include media-breakpoint-up(sm) {
        border-top: 10px solid $white;
        border-bottom: 10px solid $white;
    }

    .container-default {
        .container-holder {
            h2 {
                color: $white;
                text-align: center;
                margin-bottom: 35px;
            }
            .grid {
                align-items: center;

                .item {
                    flex: 0 !important; // !important needed to override base css

                    .card {
                        background: transparent;
                        border: none;

                        &-image {
                            background: transparent;
                            font-size: 0;
                        }
                        &-body {
                            background: transparent;
                            display: none !important; // !important needed to override base css
                        }
                    }
                }
            }
        }
    }
}
.bg-wood {
    background: url("/images/wood-tile.jpg") no-repeat center / 100% 100%;
    padding: 50px 0;

    @include media-breakpoint-up(sm) {
        padding: 60px 0 100px;
    }

    .container-default {
        .container-holder {
            text-align: center;

            h2 {
                color: $white;

                @include media-breakpoint-down(sm) {
                    font-size: $font-size-base * 2.375;
                }
            }
            p {
                max-width: 805px;
                color: $white;
                margin: auto auto 65px;
            }
            .grid {
                .item {
                    margin: 0 0 60px 0;

                    @include media-breakpoint-up(sm) {
                        @include make-col(6);
                    }
                    .card {
                        border-radius: 0;
                        border: none;
                        box-shadow: 2px 2px 8px rgba($black, 0.16);
                        padding: 5px;
                        overflow: unset;

                        @include media-breakpoint-up(sm) {
                            padding: 10px;
                        }

                        &-body {
                            .card-caption {
                                position: relative;

                                .card-title-link {
                                    background: $secondary;
                                    padding: 16px;
                                    color: $white;
                                    width: 90%;
                                    position: absolute;
                                    top: -45px;
                                    left: 50%;
                                    transform: translateX(-50%);

                                    &:hover {
                                        background: $white;
                                        color: $blue;
                                        box-shadow: 0 3px 5px rgba($black, 0.16);
                                    }

                                    .card-title {
                                        font-size: $font-size-base * 1.125;
                                        font-family: $font-familty-nothing;
                                        font-weight: $font-weight-regular;
                                        text-align: center;

                                        @include media-breakpoint-up(sm) {
                                            font-size: $h3-font-size;
                                        }
                                    }
                                }
                                .card-subtitle {
                                    font-size: $h3-font-size;
                                    font-weight: 500;
                                    color: $blue;
                                    margin-top: 25px;
                                    margin-bottom: 5px;
                                    text-align: center;

                                    @include media-breakpoint-down(sm) {
                                        display: none;
                                    }
                                }
                                .card-description {
                                    min-height: 100%;
                                    max-height: 100%;
                                    text-align: center;
                                    margin-top: 25px;
                                    margin-bottom: 20px;

                                    &-content {
                                        font-size: $font-size-base;
                                        color: $base-1;
                                    }
                                    .list {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;

                                        &-item {
                                            color: $blue;

                                            .icon {
                                                i {
                                                    font-weight: $font-weight-regular;
                                                }
                                            }
                                        }
                                    }
                                    &::after {
                                        content: none;
                                    }
                                }
                            }
                            .card-buttons {
                                position: relative;

                                .card-btn {
                                    @extend .btn-white;
                                    position: absolute;
                                    bottom: -40px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: max-content;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.content.arrangements {
    position: relative;
    padding: 60px 0;

    @include media-breakpoint-up(sm) {
        padding: 70px 0;
    }

    &::after {
        content: "";
        background: url("/images/background-tile.jpg") no-repeat center / 100% 100%;
        opacity: 0.3;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }
    .container-default {
        .container-holder {
            text-align: left;

            h2 {
                font-size: $h2-font-size;
                color: $base-1;
                margin-bottom: 20px;
            }
        }
    }
    .container-three-columns {
        .container-holder {
            .column.one {
                p {
                    font-size: $font-size-base;
                }
                .btn {
                    @extend .btn-white;
                    font-family: $font-Barlow;
                    font-weight: 600;
                }
            }
            .column.two {
                @include media-breakpoint-down(sm) {
                    margin-top: 40px;
                }
            }
            .column.two,
            .column.three {
                h3,
                a {
                    color: $blue;
                }
                h3 {
                    margin-bottom: 25px;
                    font-weight: $font-weight-base;
                }
                p {
                    font-size: $font-size-base_sm;

                    a {
                        font-size: $font-size-base;
                        position: relative;
                        padding-right: 10px;

                        &:hover {
                            text-decoration: none;

                            &::after {
                                right: -5px;
                            }
                        }
                        &::after {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            content: "\f054";
                            transform: translateY(-45%);
                            font-family: $font-awesome;
                            font-weight: 700;
                            font-size: $font-size-base_xxs;
                            transition: 300ms all;
                        }
                    }
                }
            }
        }
    }
}
.image-slider.large {
    position: relative;
    background: transparent !important; // !important needed to override base css
    padding: 75px 15px 0 !important; // !important needed to override base css
    margin-top: 0 !important; // !important needed to override base css
    border-bottom: none !important; // !important needed to override base css

    @include media-breakpoint-up(xl) {
        padding: 95px 85px 0 !important;
    }

    &::after {
        content: "";
        background: url("/images/background-tile.jpg") no-repeat center / 100% 100% !important; // !important needed to override base css
        opacity: 0.3;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }
}
.content {
    &.overview {
        position: relative;
        padding: 50px 0 70px !important; // !important needed to override base css

        @include media-breakpoint-up(sm) {
            padding: 50px 0 80px !important; // !important needed to override base css;
        }

        &::after {
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            background: url("/images/background-tile.jpg") no-repeat center / 100% 100% !important; // !important needed to override base css
            opacity: 0.3;
        }
        .container-default {
            .container-holder {
                h1 {
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

// detail
.content {
    &.detail,
    &.overview {
        position: relative;
        padding: 66px 0 80px;

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            content: "";
            background: url("/images/background-tile.jpg") no-repeat 50% / 100% 100%;
            opacity: 0.3;
        }
        > .container-default {
            > .container-holder {
                .blog_detail,
                .accommodation_detail,
                .service_detail,
                .service_category_detail {
                    padding-bottom: 0 !important;
                    .info {
                        h1,
                        p {
                            text-align: left;
                        }
                        .btn-back {
                            display: block;
                            position: relative;
                            color: $blue;
                            padding-left: 15px;
                            margin-bottom: 6px !important; // !important needed to override base css
                            font-family:  $font-family-barlow;
                            font-weight: $headings-font-weight;

                            &:hover {
                                text-decoration: none;

                                &::before {
                                    left: -5px;
                                }
                            }
                            &::before {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                content: "\f053";
                                transform: translateY(-50%);
                                font-family: $font-awesome;
                                font-weight: 700;
                                font-size: $font-size-base;
                                transition: 300ms all;
                            }
                        }
                        h1 {
                            margin-bottom: 35px;
                        }
                        .accommodation-properties {
                            margin-top: -30px;

                            &__icon {
                                i {
                                    font-weight: $font-weight-base;
                                }
                            }
                            &__item {
                                margin-right: 40px;
                            }
                            &__icon,
                            &__label {
                                color: $blue;
                            }
                        }
                    }
                    .info-extra {
                        margin-bottom: 0 !important;

                        .gallery {
                            &-item {
                                background: $white;
                                padding: 10px;
                                box-shadow: 2px 2px 8px rgba($black, 0.16);
                                margin-top: 26px;
                                &.first-item {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }
            }
            .service_detail {
                .info {
                    h1 {
                        margin-bottom: 15px !important; // !important needed to override base css
                    }
                }
            }
        }
    }
    &.overview {
        > .container-default {
            > .container-holder {
                .service_category_detail {
                    .info {
                        h1 {
                            margin-bottom: 15px !important; // !important needed to override base css
                        }
                    }
                }
            }
        }
    }
}
// detail
.content {
    &.contact {
        position: relative;
        padding: 66px 0 80px;

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            content: "";
            background: url("/images/background-tile.jpg") no-repeat 50% / 100% 100%;
            opacity: 0.3;
        }
        h1 {
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;

            a {
                color: $blue;
                text-decoration: underline;
                text-transform: capitalize;
            }
        }
    }
}
.form {
    .form-group {
        &.form-titleelement {
            h3 {
                font-family: $font-familty-nothing;
                font-weight: $font-weight-regular;
                margin-top: 30px;
                margin-bottom: 15px;
            }
        }
        &.form-contentelement {
            p {
                margin-bottom: 25px;
            }
            ul {
                list-style: none;
                padding-left: 15px;
                margin-bottom: 30px;

                li {
                    position: relative;
                    padding-left: 18px;

                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }

                    &::before {
                        position: absolute;
                        top: 40%;
                        left: 0;
                        content: "\f111";
                        transform: translateY(-100%);
                        font-family: $font-awesome;
                        font-weight: 700;
                        font-size: $font-size-base / 2;
                        transition: 300ms all;
                    }
                    a {
                        color: $blue;
                    }
                }
            }
        }
        .form-check {
            padding-left: 0;

            &-input {
                padding: 0;
                height: initial;
                width: initial;
                margin-bottom: 0;
                display: none;
                cursor: pointer;

                &:checked + .form-check-label {
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 7px;
                        left: 5px;
                        width: 5px;
                        height: 11px;
                        border: solid $blue;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }
            }
            .form-check-label {
                position: relative;

                &::before {
                    content: "";
                    background-color: $white;
                    border: 1px solid $base-3;
                    border-radius: 3px;
                    padding: 7px;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 5px;
                }

                a {
                    color: $blue;
                }
            }
        }
        .btn-primary {
            margin: 10px 0;
            @extend .btn-white;
        }
        .form-control-label {
            margin-bottom: 2px;
        }
    }
}
small {
    font-size: $font-size-base_sm;
    font-weight: $font-weight-base;

    a {
        color: $blue;
    }
}

/* accommodation_detail */
.accommodation_detail {
    .tommy {
        margin-top: 6vh;
        background-color: $white;
        box-shadow: 2px 2px 8px rgba($black, 0.15);
    }
}
