.grid {
	&.blog_overview,
	&.accommodation_overview,
	&.service_overview,
	&.service_category_overview {
		flex-wrap: wrap !important; // !important needed to override base css
		border: none !important; // !important needed to override base css

		.item {
			margin: 0 0 60px 0;

			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}
			.card {
				border-radius: 0;
				border: none;
				box-shadow: 2px 2px 8px rgba($black, 0.16);
				padding: 5px;
				overflow: unset;

				&-body {
					.card-caption {
						position: relative;

						.card-title-link {
							background: $secondary;
							padding: 16px;
							color: $white;
							width: 90%;
							box-shadow: 3px 3px 6px rgba($black, 0.16);
							position: absolute;
							top: -45px;
							left: 50%;
							transform: translateX(-50%);

							&:hover {
								background: $white;
								color: $blue;
								box-shadow: 0 3px 5px rgba($black, 0.16);
							}

							.card-title {
								font-size: $h3-font-size;
								font-family: $font-familty-nothing;
								font-weight: $font-weight-regular;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								text-align: center;
							}
						}
						.card-subtitle {
							font-size: $font-size-base * 1.125;
							color: $blue;
							margin-top: 25px;
							text-align: center;
							font-weight: 500 !important;

							@include media-breakpoint-up(sm) {
								font-size: $h3-font-size;
							}
						}
						.card-description {
							min-height: 100%;
							max-height: 100%;
							text-align: center;
							margin: 20px 0;
							&-content {
								font-size: $font-size-base;
								color: $base-1;
							}
							.list {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								margin-top: 15px;

								&-item {
									color: $blue;

									.icon {
										i {
											font-weight: $font-weight-regular;
										}
									}
								}
							}
							&::after {
								content: none;
							}
						}
					}
					.card-buttons {
						position: relative;

						.card-btn {
							@extend .btn-white;
							padding: 5px 24px !important; // !important needed to override base css
							width: max-content;
							position: absolute;
							bottom: -35px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
		}
	}
	&.blog_overview {
		.item {
			.card {
				.card-body {
					.card-caption {
						.card-subtitle {
							font-size: $font-size-base !important; // !important needed to override base css
						}
					}
				}
			}
		}
	}
}

&.home .card {
	.card-description-content p {
		color: $base-1 !important;
	}
}
