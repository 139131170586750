// common element styles
p {
    font-size: $font-size-base;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
h1,
h2 {
    font-family: $font-familty-nothing !important; // !important needed to override base css
    font-weight: $font-weight-regular !important; // !important needed to override base css
}
h1 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.875 !important;
	}
}
h2 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.375;
	}
}
// Common reusable placeholders classes
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.list-default {
    margin: 0;
    padding: 0;
    list-style: none;
}
.main {
    overflow-x: hidden;
}

.site_switcher {
    z-index: 2; 
}