.eyecatcher,
.image-slider.large {
    background: $primary;
    padding: 10px 15px 15px;    

    @include media-breakpoint-up(xl) {
        margin-top: 120px;
        padding: 60px 85px 35px;
    }

    &.large {
        @include media-breakpoint-down(sm) {
            min-height: 35vh;
        }
        @include media-breakpoint-up(md) {
            min-height: 540px;
        }
        overflow-x: hidden;

        .container-default {
            .container-holder {
                .owl-carousel {
                    @include media-breakpoint-down(sm) {
                        min-height: 35vh;
                    }
                    @include media-breakpoint-up(md) {
                        min-height: 540px;
                    }
                    border: none;
                    padding-bottom: 30px;

                    .owl-stage-outer {
                        overflow: visible;                        

                        .owl-stage {
                            .owl-item {
                                opacity: 0;
                                border: 10px solid $white;
                                box-shadow: 0 3px 10px rgba($black, 0.15);
                                

                                &.active {
                                    opacity: 1;
                                }
                                .item {
                                    @include media-breakpoint-down(sm) {
                                        min-height: 35vh;
                                    }
                                    @include media-breakpoint-up(md) {
                                        min-height: 540px;
                                    }

                                    .owl-container {
                                        position: static;

                                        .owl-caption {
                                            .owl-title {
                                                position: absolute;
                                                top: 30px;
                                                right: 0;
                                                background: $secondary;
                                                padding: 5px 15px;
                                                width: 200px;
                                                font-size: $font-size-base * 1.375;
                                                font-family: $font-familty-nothing;
                                                font-weight: 400;
                                                transform: translateX(10px);

                                                @include media-breakpoint-up(sm) {
                                                    width: max-content;
                                                    transform: translateX(20px);
                                                }
                                                @include media-breakpoint-up(lg) {
                                                    font-size: $font-size-base * 2;
                                                }
                                                @include media-breakpoint-up(xl) {
                                                    font-size: $font-size-base * 2.375;
                                                    transform: translateX(40px);
                                                }
                                            }      
                                        }
                                        .owl-link {
                                            .owl-btn {
                                                position: absolute;
                                                bottom: -20px;
                                                left: 50%;
                                                transform: translateX(-50%);
                                                @extend .btn-white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .owl-dots {
                        bottom: 110px;
                    }
                }
            }
        }
    }
    .container-default {
        .container-holder {
            .owl-carousel {
                border: 6px solid $white;

                @include media-breakpoint-up(sm) {
                    border: 10px solid $white;
                }
                .owl-nav {
                    display: none;
                }
            }
        }
    }
}
