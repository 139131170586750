.mini-sab {
    @include media-breakpoint-down(lg) {
        position: relative;
        margin-top: -10px;
        padding: 0 0 15px 0;
        background: $primary;
        border-bottom: 10px solid $white;
        box-shadow: 0 3px 10px rgba($black, 0.15);
    }
    @include media-breakpoint-up(xl) {
        height: 0;
        padding: 0;
        background: none;
    }
    .container {
        padding: 0 15px;
        @extend .d-flex;
        @extend .justify-content-center;
        .container-holder {
            position: relative;
            padding: 4px 15px;
            background: $white;
            z-index: 9;
            border-radius: 38px;
            box-shadow: 0 3px 10px rgba($black, 0.25);
            @include media-breakpoint-down(lg) {
                margin: -80px 15px 0 15px;
            }
            @include media-breakpoint-up(xl) {
                position: absolute;
                left: auto;
                right: auto;
                margin: -95px auto 0 auto;
            }
        }
    }
}