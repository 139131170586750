html { font-size: initial;}
body { font-size: $font-size-base;}

#TommyBookingSupport {
  .tbs-container-fluid {
    @extend .px-0;
  }

  .tbs-navbar-collapse {
    @extend .px-0;
  }
  
  .tbs-nav {
    li {
      font-size: 14px;
    }
  }

  .tbs-page-overzicht {
    #coupon_code {
      h2 {
        margin-top: 20px;
      }
    }
  }
}

/* button colors */
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
  background-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info { 
  background-color: $primary;
  border-color: $primary;
  color: $white; 
}
#TommyBookingSupport .tbs-btn-info:hover {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-success { 
  background-color: $primary;
  border-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-success:hover {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
  transition: 0.5s;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
  background-color: $primary !important;
}



/* custom - op basis van TommyBookingSupport MINI Widget */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types { order: 1;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories { order: 0;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date { order: 2;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-confirm-update { order: 3;}

.mini-sab .tommy-zeb-wrapper {
    float: none;
    width: auto;
}
.mini-sab .tommy-zeb-wrapper * { 
    font-family: 'Barlow', sans-serif !important;
    font-size: 16px;
}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
    float: none;
    color: $body-color;
    background: none;
    padding: 0 !important;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini h2.ng-binding { display: none; }
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { justify-content: flex-end;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid $primary;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label { display: none;}

/* Accommodatietype */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types { display: block !important;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .ng-isolate-scope { 
    padding: 5px 0px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .tommy-zeb-accommodation-type-choice::before {
    display: block;
    content: 'Type verblijf';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}
.mini-sab [data-language="de"] .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .tommy-zeb-accommodation-type-choice::before {
    content: 'Art des Aufenthalts';
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-begin-label label { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-begin-label::before {
    display: block;
    content: 'Aankomst';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}
.mini-sab [data-language="de"] .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-begin-label::before {
    content: 'Ankunft';
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-end-label label { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-end-label::before {
    display: block;
    content: 'Vertrek';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}
.mini-sab [data-language="de"] .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date-end-label::before {
    content: 'Abfahrt';
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input { line-height: 20px;}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select { padding: 5px; margin: 0; width: 160px; color: #767d77; border-bottom: 1px solid rgba($primary, 0.15) !important; border-radius: 0;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin { margin-right: 10px;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label { float: none !important; display: block; line-height: 21px !important;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label label { margin-bottom: 0 !important; display: block;}
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin,
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-end {
    padding: 5px 0px;
    float: left;
    margin-bottom: 0;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories {
  padding-right: 10px;
  border-right: 1px solid $primary;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
    border-right: none;
    padding-right: 0;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select, .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
    background-color: $white;
    border:none;
    padding: 7px;
}


.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
    float: none;
    margin: 9px 0 0 0 !important;
    height: 48px;
    border: none;
    border-radius: 24px;
    background-color: $blue;
    font-family: 'Barlow', sans-serif !important;
    box-shadow: 2px 2px 8px rgba($black, 0.15);
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
}
.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn:hover {
    background-color: $primary;
    color: $white;
}


/* collapse functionaliteit */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding {
  display: block;
  margin: 5px 0 0 0;
  color: #22333b;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 21px;
  text-align: left;
  text-transform: none;
  //font-family: $font-family-barlow;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding::after { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse {
  display: flex;
  min-width: auto;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse .tommy-zeb-travelcompany-total {
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 6px;
  width: 50px !important;
  height: 36px;
  margin-top: 0 !important;
  margin-left: 15px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list {
  z-index: 99;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 10px rgba($black, 0.25);
  color: #000;
  width: 100%;
  max-width: 100%;
  min-width: 260px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list .ng-scope { padding: 0;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category {
  flex-wrap: nowrap;
  margin: 5px 0;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-label {
  flex: 1 1 auto;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-choice {
  flex: 0 0 85px;
  align-self: center;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-choice .ng-binding {
  min-width: 20px;
  line-height: 22px;
  text-align: center;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range {
  background-color: $blue;
  color: #fff;
  line-height: 22px;
  transition: all 0.5s ease;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range:hover {
  background-color: $primary;
  color: #fff;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range span { 
  line-height: 24px; 
  top: 0!important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories {
  height: 31px;
  line-height: 16px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse button.tommy-zeb-btn { margin-top: 5px !important;}


@media (max-width: 1199px) {

  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { justify-content: center;}
  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label,
  .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
  .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}

}
@media (max-width: 991px) {

  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { flex-direction: column; padding: 10px 5px !important;}
  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types,
  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories {
      border-right: none;
      padding: 0;
      margin: 0;
  }
  .mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
  .mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
  .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
      display: block !important;
      width: 100% !important;
  }
  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { display: block;}
  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types,
  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
  .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date,
  .mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope { float: none; clear: both;}
  .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn { margin: 15px 0 0 0 !important;}
  .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label, 
  .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}
  .mini-sab .tommy-zeb-wrapper-mini tommy-confirm-update { display: block;}
  .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }

}



/* custom - op basis van TommyBookingSupport */
.search_book { @extend .clearfix;}
.search_book .tommy-zeb-wrapper * { font-family: 'Barlow', sans-serif !important;}
.search_book .tommy-zeb-wrapper .tommy-zeb-left { background: $primary;}
.search_book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope { background: rgba($primary, 0.1);}
.search_book .tommy-zeb-wrapper .tommy-zeb-left h1 { display: none;}
.search_book .tommy-zeb-wrapper * h2 { 
    margin: 15px 0 10px 0 !important;
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 700;
    font-family: 'Barlow', sans-serif !important;
}
.search_book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 { margin-top: 0 !important;}
.search_book .tommy-zeb-wrapper .tommy-zeb-btn {
    padding: 7px 30px;
    border-radius: 18px;
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    font-family: 'Barlow', sans-serif !important;
}
.search_book .tommy-zeb-wrapper .tommy-zeb-btn:hover {
    border-color: $secondary;
    background-color: $secondary;
    color: $white;
}
.search_book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding { 
    margin-bottom: 10px;
    color: $primary;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Barlow', sans-serif !important;
}
.search_book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
    color: $black;
    font-weight: 700;
}

@media (min-width: 1200px) {
    /* custom - op basis van TommyBookingSupport - Zoek en Boek */
    .search_book .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 250px;}
    .search_book .tommy-zeb-wrapper .tommy-zeb-result-details { width: 520px;}
}
@media (min-width: 980px) and (max-width: 1199px) {
    /* custom - op basis van TommyBookingSupport - Zoek en Boek */
    .search_book .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 180px;}
}