/* bootstrap > colors
   ========================================================================== */
$primary: #98a298;
$secondary: #605f5f;
$blue: #1b7dab;
$body-color: #22333b;

/* matrix-colors
   ========================================================================== */
$matrix-color-primary: #98a298;
$matrix-color-secondary: #605f5f;
$matrix-color-tertiary: #1b7dab;

/* bootstrap > font
       ========================================================================== */
@import url("https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600&display=swap");

$font-nothingyoucouldo: "Nothing You Could Do", sans-serif;
$font-Barlow-Condensed: "Barlow Condensed", sans-serif;
$font-Barlow: "Barlow", sans-serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-Barlow-Condensed !default;
$font-family-barlow: $font-Barlow;
$font-familty-nothing: $font-nothingyoucouldo;
$font-weight-regular: 400;
$font-weight-base: 500;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base * 1.375 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base_sm !default;
$h6-font-size: $font-size-base_xs !default;

$headings-font-weight: 600;
