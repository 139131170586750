.header {
	.header-socket {
		background-color: $primary;

		@include media-breakpoint-down(xl) {
			width: 100%;
			order: 2;
		}
		@include media-breakpoint-up(xl) {
			padding: 0 85px;
		}

		.container-navbar {
			@include media-breakpoint-down(lg) {
				position: absolute;
			}

			.container-holder {
				.column.navbar {
					@media screen and (max-width: 575px) {
						padding: 30px 0 0;
					}
					padding: 16px 0 0;

					.navbar-nav {
						.nav-item {
							width: 21px;
							height: 21px;
							margin-right: 10px;
							overflow: hidden;
							border-radius: 100%;

							&.active {
								.nav-link {
									opacity: 1;
								}
							}

							.nav-link {
								padding: 8px 8px 0;
								font-size: 1.125rem;
								line-height: 1;
								padding: 0;
								opacity: 0.7;

								&:hover {
									opacity: 1;
								}

								img {
									max-width: 100%;
									transform: scale(1.6);
								}
							}
						}
					}
				}
			}
		}
	}

	.header-main {
		background-color: $primary;

		@include media-breakpoint-up(xl) {
			padding: 0 85px;
		}

		.container-navbar {
			.container-holder {
				.column.navbar {
					padding: 0 0 4px 16px;

					@include media-breakpoint-down(lg) {
						justify-content: flex-start;
					}

					.logo {
						max-width: 130px;
						order: 2;

						@media screen and (max-width: 375px) {
							max-width: 100px;
						}
						@include media-breakpoint-up(sm) {
							max-width: 229px;
						}
						@include media-breakpoint-up(xl) {
							order: 1;
						}
					}

					.navbar-toggler {
						order: 1;

						@media screen and (max-width: 320px) {
							margin: 0 !important;
						}
						@include media-breakpoint-down(lg) {
							margin: 0 30px 0 0;
						}

						&-icon {
							color: $white;
						}
					}

					.navbar-collapse.menu {
						order: 2;

						@include media-breakpoint-down(lg) {
							order: 4;

							.navbar-nav {
								padding-right: 20px;
							}
						}

						.navbar-nav {
							@include media-breakpoint-up(xl) {
								align-items: center;
							}

							.nav-item {
								@include media-breakpoint-down(lg) {
									&:not(:last-child) {
										border-bottom: 1px solid $white;
										margin-bottom: 10px;
									}
								}

								.nav-link {
									color: $white;
									font-size: $font-size-base * 1.125;
									padding: 2px 0;

									@include media-breakpoint-up(xl) {
										padding: 0 25px 0 8px;
										padding-right: 25px;
									}

									&:hover {
										opacity: 0.7;
									}
								}

								.dropdown-toggle {
									display: flex;
									align-items: center;
									justify-content: space-between;

									@include media-breakpoint-down(lg) {
										&[aria-expanded="true"] {
											&::after {
												content: "\f068";
											}
										}
										&::after {
											margin-right: 3px;
											content: "\F067";
											font-family: $font-awesome;
											font-size: 0.813rem;
											font-weight: 700;
											border: none;
										}
									}
								}

								.dropdown-menu {
									background: transparent;
									border: none;

									&.show {
										@include media-breakpoint-up(xl) {
											background: $white;
										}

										.nav-item {
											&.last {
												padding: 0;
											}

											.nav-link {
												color: $white;
												font-size: $font-size-base_sm;
												padding: 8px;

												@include media-breakpoint-up(xl) {
													color: $primary;

													&:hover {
														background: $primary;
														color: $white;
													}
												}

												&:hover {
													color: $white;
													opacity: 0.7;
												}
											}
										}
									}
								}
							}
						}
					}

					.search-book-button {
						order: 3;

						li {
							a {
								color: $white;
								border-radius: 18px;
								border: 1px solid;
								padding: 2px 10px;
								text-decoration: none;
								font-size: $font-size-base_xs;
								position: absolute;
								top: 10px;
								right: 76px;
								background: #1b7dab;
								border-color: #1b7dab;

								@media screen and (max-width: 375px) {
									font-size: $font-size-base_xxs;
								}
								@media screen and (max-width: 575px) {
									top: 30px;
								}
								@include media-breakpoint-up(sm) {
									font-size: $font-size-base;
									border: 2px solid $white;
								}
								@include media-breakpoint-up(xl) {
									position: relative;
									padding: 7px 10px;
									top: 8px;
									right: 0;
								}

								&:hover {
									background: $white;
									color: $primary;
								}
							}
						}
					}
				}
			}
		}
	}

	&.sticky {
		.page_block.logo {
			max-width: 150px !important; // !important needed to override base css
		}
	}
}


&.accommodation_book {
	.header.sticky {
		position: absolute !important;
		box-shadow: none;
	}
}
